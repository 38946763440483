<template>
  <div
    class="rounded-md bg-yellow-50 p-4"
  >
    <div class="flex">
      <p class="text-sm font-medium text-yellow-800">
        Use an account with the same email address as the invite to join.
      </p>
    </div>
  </div>
</template>
